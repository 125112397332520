<template>

  <button v-if="isActive" @click="getSnData()" class="btn-success">
    <div v-if="sn == this.$store.getters.sns.length">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div :class="Cls" v-else v-html="label"></div>
  </button>
  <button v-else @click="getSnData()" class="btn-info">
    <div class="overview" v-if="sn == this.$store.getters.sns.length">
      {{ $t('SubNavBar.Overview') }}
    </div>
    <div :class="Cls" v-else v-html="label"></div>
  </button>
</template>

<script>
import { store } from "@/store";

export default {
  name: "Serialnumber",
  data() {
    return {
      chart_date: this.$dayjs().format('YYYY-MM-DD'),
    };
  },
  props: {
    sn: {
      required: true,
      type: undefined,
    },
    isActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    Cls: {
      type: String,
      required: false,
      default: "butt"
    },
  },
  mounted() {
    if (this.isActive && this.$store.getters.sns[this.sn - 1].sn == -1) {
      this.getSnData();
    }
  },
  computed: {
    isActiveComputed() {
      return this.sn === this.$store.state.sn;
    }
  },
  methods: {

    resetSelectedOptions() {
      this.$emit('reset-options');
    },
    setActive() {
      let sns = this.$store.getters.sns;
      sns = sns.map((sn) => {
        if (sn.sn == this.$store.getters.sns[this.sn - 1].sn) {
          sn.isActive = true;
        } else sn.isActive = false;
        return sn;
      });
      this.$store.commit("updateSns", sns);
      this.$store.commit("setSn", this.$store.getters.sns[this.sn - 1].sn);
    },
    getSnData() {
      this.setActive();
      let time = this.$store.getters.chartSelector.time_smallChart !== "" ?
      this.$store.getters.chartSelector.time_smallChart : `week_${chart_date}`;
      console.log("time", time);
      if (this.$store.getters.sn != -1 && !this.isElectrician) {
        this.$emit('reset-options');
        this.$store.dispatch("get_energy_chart_data", {
          sn: store.getters.sn,
          m2: true,
          m4: true,
          m5: true,
          days: time,

        });
        // let parames = {
        //   sn: store.getters.sn,

        //   days: time,
        // };
        // this.$store.dispatch("get_soc_chart_data", parames);


      } else if (this.electrician) {
        this.$emit('reset-options');
      } else {
        // console.log("overview");
        this.$store.dispatch("get_energy_chart_data_overview", {
          m2: true,
          m4: true,
          m5: true,
          days: time,
        });
        // this.$store.dispatch("get_Soc_chart_data_overview", {

        //   days: 7,
        // });
        this.$emit('reset-options');

      }

    },
  },
};
</script>

<style></style>
