<template>
  <div id="dashChart">
    <div class="row" style="justify-content: flex-end;">
      <div class="col pr-1 col-xl-7" style="padding-right: 8px !important;">
        <Multiselect :class="{
          'text-black': !darkMode,
          'text-white': darkMode,
          'dark-mode': darkMode,
        }" v-if="!isSmallScreen || isTouchScreen && !isAppleDevice" v-model="tempSeriesSelected" :options="seriesLabel"
          :multiple="true" :searchable="false" label="text" track-by="value" @input="updateSeriesSelected"
          placeholder="" deselect-label="" select-label="" selected-label="">
          <!-- Customize how dropdown options are rendered -->
          <template #option="{ option }">
            <div class="custom-option">
              <span class="custom-dot"
                :style="{ border: `2px solid ${isOptionSelected(option) ? '#B0C5FB' : '#C6CAD5'}` }">
                <span v-if="isOptionSelected(option)" class="selected-dot"></span>
              </span>
              {{ option.text }}
            </div>
          </template>

          <!-- Customize how selected items are rendered -->
          <template #selected="{ option }">
            <div class="custom-selected">
              <span class="custom-dot" style="border: 2px solid #B0C5FB !important;">
                <span class="selected-dot"></span>
              </span>
              {{ option.text }}
            </div>
          </template>
        </Multiselect>
        <b-form-select v-else :class="{
          'rounded': true,
          'text-black': !darkMode,
          'text-white': darkMode
        }" v-model="seriesSelected" :options="seriesLabel" @change="drawChart" multiple />
      </div>
      <div class="col col-lg-2 pl-1 pr-lg-1">

        <b-form-select :class="{
          'rounded': true,
          'text-black': !darkMode,
          'text-white': darkMode
        }" v-model="timeSelected" :options="timeSeries" @change="drawChart" />


      </div>
      <div class="col-12 col-lg-3 mt-2 mt-lg-0 pl-lg-1">

        <div :class="{ 'border': true, 'rounded': true, 'text-black': !darkMode, 'text-white': darkMode }">

          <VueDatePicker style="height:36px" v-model="date" color="#285891" :format="fixedDate" fullscreen-mobile
            @onChange="drawChart" :allow-overflow="false" :locale="{ lang: lang }" :type="timeSelected == 'day'
              ? 'date' : timeSelected == 'quarter' ? 'date' : timeSelected == 'interval' ? 'date' : timeSelected"
            :max-date="today" :range="timeSelected === 'interval'" />
        </div>
      </div>
    </div>
    <div class="mt-3 position-relative">
      <div class="chart-wrapper" style="height:29cqmax; position: relative;">
        <!-- <loadOverlay v-if="this.showLoadingSpinner" /> -->
        <LoadingPanel v-if="showLoadingSpinner" class="loading-panel" />
        <canvas id="chart" ref="chartWrapper" />
      </div>
    </div>
    <button v-if="timeSelected !== 'interval'" @click="changeDate(-1)" class="chart-arrow left-arrow">
      <i class="fas fa-arrow-left"></i>
    </button>
    <button v-if="timeSelected !== 'interval'" @click="changeDate(1)" class="chart-arrow right-arrow">
      <i class="fas fa-arrow-right"></i>
    </button>
    <div style="position: absolute; right: .5vw; bottom: 2.5vh;">
      <button class="btn btn-primary" style="padding: 1px;" :title="$t('bigchart.Export CSV')"
        @click="exportToCSV(seriesSelected)">
        <i class="mdi mdi-download-outline"></i>
      </button>
    </div>
  </div>
</template>

<script>
import loadOverlay from "@/components/loadOverlay";
import LoadingPanel from '../LoadingPanel.vue';
import { mapState } from 'vuex';
import * as Chart from "chart.js";
import { getConfig, getOptions, getBarOptions } from "./chart/configs";
import i18n from "@/i18n";
import { EventBus } from 'src/EventBus.js';
import { createDataSet, getProcessedChartData, createbarDataSet } from "./chart/utils";
import {
  getSeriesUnit,
  getSeriesLabel,
  getSeriesLabel_short,
  getSeries,
  getTimeSeries,
} from "./chart/constants";
import { addDays, addMonths, addYears } from 'date-fns';
import { t } from "element-ui/lib/locale";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
  name: "Chart",
  components: { LoadingPanel, loadOverlay, Multiselect },
  data() {
    return {

      chartData: null,
      isLoading: false,
      showLoadingSpinner: false,
      chart: null,
      data: null,
      curentChartData: null,
      canvasContext: null,
      allChartData: null,
      today: new Date(),
      hasM1: true,
      timeSelected: this.$store.getters.chartSelector.timeSelected,
      seriesSelected: Array.isArray(this.$store.getters.chartSelector.seriesSelected)
        ? this.$store.getters.chartSelector.seriesSelected
        : ["m0"],
      tempSeriesSelected: [],
      date:
        this.$store.getters.chartSelector.date == ""
          ? this.$dayjs().format("ddd MMM DD YYYY 00:mm:ss")
          : this.$store.getters.chartSelector.date,
      showLegend: false,
      x_axis_label: this.$i18n.t('bigchart.day'),
      x_axis_type: "category",
      intervalId: null,
    };
  },

  created() {

  },
  watch: {
    // isLoading2(newVal) {
    //   if (newVal) {
    //     this.loadingTimeout = setTimeout(() => {
    //       this.showLoadingSpinner = true;
    //     }, 1000);
    //   } else {
    //     clearTimeout(this.loadingTimeout); 
    //     this.showLoadingSpinner = false;
    //   }
    // },
    curentSn(newVal, oldVal) {
      if (newVal != oldVal) this.drawChart();
    },
    seriesSelected: function (newSeriesSelected, oldSeriesSelected) {
      this.$store.commit("setUpdateChart", true);
      // console.log("oldSeriesSelected: ", oldSeriesSelected, "newSeriesSelected: ", newSeriesSelected, "timeSelected: ", this.timeSelected);
      if (newSeriesSelected == "m3" || newSeriesSelected == "m7") {
        if (this.timeSelected == "quarter" || this.timeSelected == "interval") {
          this.date = this.$dayjs().format("ddd MMM DD YYYY HH:mm:ss");
          this.timeSelected = "day";
        }
        this.hasM1 = false;

      } else if (oldSeriesSelected == "m3" || oldSeriesSelected == "m7") {
        if (this.timeSelected == "day") {
          this.timeSelected = "quarter";
        }
        this.hasM1 = true;
      }
    },
    timeSelected: function (newTimeSelected, oldTimeSelected) {
      // console.log("oldTimeSelected: ", oldTimeSelected, "newTimeSelected: ", newTimeSelected);
      if (newTimeSelected == "interval") {
        this.date = {
          start: this.$dayjs().subtract(1, "month").format("ddd MMM DD YYYY 00:mm:ss"),
          end: this.$dayjs().format("ddd MMM DD YYYY 00:mm:ss")
        };
      } else if (oldTimeSelected == "interval") {
        this.date = this.$dayjs().format("ddd MMM DD YYYY HH:mm:ss");
      }
      // this.drawChart();
    }
  },
  methods: {
    exportToCSV(seriesSelected) {
      const seriesMapping = {
        m1: i18n.t("bigchart.Grid voltage") + " in [V]",
        m2: i18n.t("bigchart.Gird Power") + " in [W]",
        m4: i18n.t("bigchart.PV_power") + " in [W]",
        m5: i18n.t("bigchart.Battery power") + " in [W]",
        m6: i18n.t("bigchart.SOC") + " in [%]",
        m7: `${i18n.t("bigchart.Energy") + " in [Wh]"},${i18n.t("bigchart.Charging")},${i18n.t("bigchart.Discharging")}`,
      };

      const formatDate = (label, timeSelected) => {
        if (timeSelected === 'interval') {
          return label;
        }
        const date = new Date(this.date);
        if (timeSelected === 'month') {
          date.setDate(label);
          return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + label).slice(-2)}`;
        } else if (timeSelected === 'year') {
          return `${date.getFullYear()} ${label}`;
        } else if (timeSelected === 'day') {
          return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${label}:${('0' + date.getMinutes()).slice(-2)}`;
        } else if (timeSelected === 'interval') {
          return this.date;
        }

        else {
          const fullDate = new Date(label);
          return `${fullDate.getFullYear()}-${('0' + (fullDate.getMonth() + 1)).slice(-2)}-${('0' + fullDate.getDate()).slice(-2)} ${('0' + fullDate.getHours()).slice(-2)}:${('0' + fullDate.getMinutes()).slice(-2)}`;
        }
      };

      if (this.chartData && this.chartData.labels && this.chartData.datasets) {
        let csvContent = `data:text/csv;charset=utf-8,\nDateTime`;

        seriesSelected.forEach(series => {
          if (series === 'm0') {
            csvContent += `,${seriesMapping.m2},${seriesMapping.m5}`;
          } else {
            csvContent += `,${seriesMapping[series] || ''}`;
          }
        });
        csvContent += '\n';

        this.chartData.labels.forEach((label, index) => {
          const formattedDate = formatDate(label, this.timeSelected);
          let row = `${formattedDate}`;
          seriesSelected.forEach(series => {
            if (series === 'm0') {
              const gridPower = Math.round(this.chartData.datasets[0].data[index]);
              const batteryPower = Math.round(this.chartData.datasets[1].data[index]);
              row += `,${gridPower},${batteryPower}`;
            } else if (series === 'm7') {
              const chargingValue = this.chartData.datasets[0].data[index] || "";
              const dischargingValue = this.chartData.datasets[1].data[index] || "";
              row += `,${chargingValue},${dischargingValue}`;
            } else {
              const value = Math.round(this.chartData.datasets.find(item => item.label === Object.values(this.seriesLabel).find(item => item.value === series).text)?.data[index] || 0);
              row += `,${value}`;
            }
          });

          csvContent += row + '\n';
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI);
        link.setAttribute("download", "exported_data.csv");
        document.body.appendChild(link);
        link.click();
      } else {
        console.error('chartData is not properly defined.');
      }
    },
    alignDataSets(data) {

      let maxLength = 0;
      let referenceTimes = [];
      Object.keys(data).forEach(sn => {
        if (data[sn].length > maxLength) {
          maxLength = data[sn].length;
          referenceTimes = data[sn].map(entry => {

            const timestampString = entry.qm_time;
            const datePart = timestampString.substring(0, timestampString.lastIndexOf(":"));
            return `${datePart}`;
          });

        }
      });

      Object.keys(data).forEach(sn => {
        const snDataByTime = {};

        data[sn].forEach(entry => {
          // Create a trimmed version of qm_time for comparison
          const trimmedTime = entry.qm_time.substring(0, entry.qm_time.lastIndexOf(":"));
          snDataByTime[trimmedTime] = entry;
        });

        const alignedData = referenceTimes.map(time => {
          if (snDataByTime[time]) {
            return snDataByTime[time];

          } else {
            return {
              qm_time: `${time}:00.000000`,
              qm_m1: null,
              qm_m2: null,
              qm_m4: null,
              qm_m5: null,
              qm_m6: null

            };
          }
        });

        data[sn] = alignedData.sort((a, b) => new Date(a.qm_time) - new Date(b.qm_time));
      });

      return data;
    },

    allChartDataSummery(arr) {
      let arrNew = [];
      let sns = Object.keys(arr);

      for (let index = 0; index < arr[sns[0]].data.length; index++) {
        let gg = Object.keys({ ...arr[sns[0]].data[0] }).reduce(
          (accumulator, currentValue) => {
            if (
              currentValue == "time" ||
              currentValue == "sn_id" ||
              currentValue == "id"
            )
              accumulator[currentValue] = arr[sns[0]].data[index][currentValue];
            else {
              try {
                var temp = 0;
                var value = 0;
                var dividedNumber =
                  currentValue == "m3" || currentValue == "m7" ? 1 : 0;
                for (let i = 0; i < sns.length; i++) {
                  temp =
                    typeof arr[sns[i]].data[index][currentValue] ===
                      "undefined" ||
                      isNaN(parseInt(arr[sns[i]].data[index][currentValue]))
                      ? null
                      : parseInt(arr[sns[i]].data[index][currentValue]);
                  value += temp;
                  // console.log("sns1111: ", sns[i],value);
                  if (
                    temp != null &&
                    value != 0 &&
                    temp != 0 &&
                    currentValue != "m3" &&
                    currentValue != "m7"
                  )
                    dividedNumber += 1;
                  // console.log("sns1111: ", sns[i],currentValue,"value::::",value ,"temp::::",temp);
                }
                if (value != 0) {

                  accumulator[currentValue] = (value / dividedNumber).toFixed(0);
                }
                // console.log( "value::::",value)
                if (
                  temp != null && currentValue != "m6" &&
                  currentValue != "m3" &&
                  currentValue != "m2" &&
                  currentValue != "m7") {
                  accumulator[currentValue] = value;
                }
                else if (temp != null && value == 0 && (currentValue == "m6" || currentValue == "m2")) {

                  accumulator[currentValue] = value;
                }

                else if (value == 0 && temp != 0 &&
                  (currentValue == "m0" ||
                    currentValue == "m2" ||
                    currentValue == "m5")) {

                  accumulator[currentValue] = null
                }


              } catch (error) {
                console.log("error: ", error);
              }
            }
            return accumulator;
          },
          {}
        );
        arrNew.push(gg);
      }

      return { data: arrNew, x_axis_type: arr[sns[0]].x_axis_type };
    },
    dateChanged() {
      if (this.timeSelected === "month") {
        this.date = this.$dayjs(this.date).format("ddd MMM DD YYYY 00:mm:ss");
      } else if (this.timeSelected === "interval") {
        this.date.start = this.$dayjs(this.date.start).format("ddd MMM DD YYYY 00:mm:ss");
        this.date.end = this.$dayjs(this.date.end).format("ddd MMM DD YYYY 00:mm:ss");
      }
      else {
        this.date = this.$dayjs(this.date).format("ddd MMM DD YYYY hh:mm:ss");
      }
      //console.log("dataLabel: ", this.seriesSelected);
      //this.getData();
    },
    createChartData(selectedSeries, radius = 1) {
      if (selectedSeries == "m0") {
        let data2;
        let data5;
        this.showLegend = true;
        if (this.timeSelected === "day" || this.timeSelected === "quarter") {
          // data2 = this.data.map((d) => (d["m2"] / d["n"]).toFixed(0));
          // data5 = this.data.map((d) => (d["m5"] / d["n"]).toFixed(0));
          data2 = this.data.map((d) => (d["m2"]));
          data5 = this.data.map((d) => (d["m5"]));
        }
        else {
          // console.log("data_out", this.data);
          data2 = this.data.map((d) => (d["m2"]));
          data5 = this.data.map((d) => (d["m5"]));
        }
        // console.log("data2", data2, "data5", data5);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [
            createDataSet(i18n.t("bigchart.Gird Power") + " (" + i18n.t("bigchart.Input_output") + ")", data2, '#42b883'),
            createDataSet(i18n.t("bigchart.Battery power") + " (" + i18n.t("bigchart.Charge_Discharge") + ")", data5, '#285891'),

          ],
        };
      }
      if (selectedSeries == "m1") {
        let data = this.data.map((d) => d[selectedSeries]);
        this.showLegend = true;
        return {
          labels: this.data.map((x) => x.time),
          datasets: [createDataSet(i18n.t("bigchart.Grid voltage"), data, '#FF77FF')],
        };
      }
      if (selectedSeries == "m2") {
        this.showLegend = true;
        let data = this.data.map((d) => d["m2"]);
        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createDataSet(
              this.$i18n.t("bigchart.Input_output"),
              data, '#42b883'
            ),
          ],
        };
      }
      if (selectedSeries == "m4") {
        this.showLegend = true;
        let data;
        if (this.timeSelected === "day" || this.timeSelected === "quarter") {
          data = this.data.map((d) => d["m4"] ? (d["m4"] / d["n"]).toFixed(0) : null);
        }
        else {
          data = this.data.map((d) => d["m4"]);
        }
        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createDataSet(
              this.$i18n.t("bigchart.PV_power"),
              data, '#dac555'
            ),
          ],
        };
      }
      if (selectedSeries == "m5") {
        this.showLegend = true;
        let data = this.data.map((d) => d["m5"]);
        //console.log("data_out", data);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [
            createDataSet(

              this.$i18n.t("bigchart.Charge_Discharge"),

              data, '#285891'
            ),
          ],
        };
      }

      if (selectedSeries == "m7") {
        this.showLegend = true;
        let data2 = this.data.map((d) => d["m7"]);
        let data5 = this.data.map((d) => d["m3"]);
        // console.log("data_out_m3", data5);
        // console.log("data_out_m7", data2);

        return {
          labels: this.data.map((x) => x.time),

          datasets: [
            createbarDataSet(i18n.t("bigchart.Energy") + " (" + i18n.t("bigchart.Charging") + ")", "#45D17E", "#45D17E", data2),
            createbarDataSet(i18n.t("bigchart.Energy") + " (" + i18n.t("bigchart.Discharging") + ")", "#D5366D", "#D5366D", data5),
          ],
        };
      }
      if (
        this.seriesSelected == "energy_forecast" ||
        this.seriesSelected == "load_forecast"
      ) {
        this.showLegend = false;
        let data = this.data.map((x) => x.data);
        //console.log("data_out", data_out);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [createDataSet("", data, '#42b883')],
        };
      }
      if (selectedSeries === "m6") {
        data = this.data.map((d) => d["m6"]);
        return {
          labels: this.data.map((x) => x.time),
          datasets: [
            {
              ...createDataSet(i18n.t("bigchart.SOC"), data, "#800080"),
              yAxisID: this.seriesSelected.length >= 1 ? "y-axis-2" : "y-axis-1", // Force m6 to the second y-axis
            },
          ],
        };
      }
      let data = this.data.map((d) => d[selectedSeries]);
      this.showLegend = false;
      return {
        labels: this.data.map((x) => x.time),
        datasets: [createDataSet("", data, '#285891')],
      };
    },
    getChartType() {
      if (this.seriesSelected == "m3" || this.seriesSelected == "m7") {
        return "bar";
      }
      return "line";
    },
    checkTimeAndDrawChart() {
      const date = new Date();
      const minutes = date.getMinutes();
      const hours = date.getHours();
      if ((minutes - 1) % 5 === 0) {
        this.drawChart();
      } else if (minutes === 0) {
        this.today = new Date();
      }
    },
    drawChart() {
      this.isLoading = true;
      this.dateChanged();
      this.getXLabel();
      this.isLoading = true;
      this.getData()
        .then(() => {
          if (this.chart) this.chart.destroy();
          // console.log("thsi.x_axis_type", this.x_axis_type)
          // console.log("this.seriesSelected.length: ", this.seriesSelected.length, this.seriesSelected);
          if (this.seriesSelected.length == 0) {
            this.seriesSelected = ["m0"];
          }
          let mergeddata = { labels: [], datasets: [] };
          this.seriesSelected.forEach((series, index) => {
            const chartData = this.createChartData(series);
            if (index === 0) {
              mergeddata.labels = chartData.labels;
            }
            mergeddata.datasets.push(...chartData.datasets);
          });
          this.chartData = mergeddata;

          if (this.getChartType() == "bar") {
            let options = getBarOptions(
              this.showLegend,
              this.seriesLabel,
              this.seriesUnit,
              this.seriesSelected,
              this.x_axis_type,
              this.x_axis_label
            );
            let config = getConfig(
              this.getChartType(),
              this.height,
              mergeddata,
              options
            );

            this.chart = new Chart(this.canvasContext, config);


          }
          else {
            let options = getOptions(
              this.showLegend,
              this.isSmallScreen ? this.seriesLabel_short : this.seriesLabel,
              this.seriesUnit,
              this.seriesSelected,
              this.x_axis_type,
              this.x_axis_label,
              this.timeSelected
            );


            let config = getConfig(
              this.getChartType(),
              this.height,
              mergeddata,
              options
            );
            this.chart = new Chart(this.canvasContext, config);
          }

        })
        .catch((error) => {
          this.isLoading = false;
          console.log("err", error);
        });
      this.isLoading = false;
      // to show the day label in chart selector
      if (
        this.$store.getters.sn == -1 &&
        this.timeSelected == "day" &&
        this.seriesSelected != "m3" &&
        this.seriesSelected != "m7"
      )
        this.timeSelected = "quarter";
    },
    getXLabel() {
      switch (this.timeSelected) {
        case "day":
          this.x_axis_label = this.$i18n.t("bigchart.Hours");
          break;
        case "month" || "interval":
          this.x_axis_label = this.$i18n.t("bigchart.Days");
          break;
        case "year":
          this.x_axis_label = this.$i18n.t("bigchart.Monthes");
          break;
        default:
          this.x_axis_label = this.$i18n.t("bigchart.Hours");
          break;
      }
    },
    get_selected_methode() {
      if (
        this.seriesSelected == "energy_forecast" ||
        this.seriesSelected == "load_forecast"
      )
        return this.seriesSelected;
      else {
        return this.getChartType() == "bar" ? "sum" : "mean";
      }
    },
    getData() {
      // This function is reponsilbe for apply the current selected settings in chart to the server and return in turn the data that belogs to
      // Test if the chart selected series is not allowed to be selected in overview model
      this.showLoadingSpinner = true;
      let not_allowed = ["m1", "m2", "m4"];
      if (this.$store.getters.sn == -1) {
        let x = this.seriesSelected.filter(item => {
          if (not_allowed.includes(item)) {
            // console.log("not allowed", item);
            return false;
          }
          return true;
        });
        this.seriesSelected = x;
        this.tempSeriesSelected = this.seriesLabel.filter(option =>
          this.seriesSelected.includes(option.value));
      }
      if (this.$store.getters.sn == -1 && this.timeSelected == "Day")
        this.timeSelected = "day";
      if (!this.seriesSelected.includes("m7") && this.timeSelected == "day") {
        this.timeSelected = "quarter";
      } else if (this.seriesSelected.includes("m7") && this.timeSelected !== "day") {
        this.date = this.$dayjs().format("ddd MMM DD YYYY HH:mm:ss");
        this.timeSelected = "day";
      }
      this.$store.commit("setChartSelector", {
        seriesSelected: this.seriesSelected,
        timeSelected: this.timeSelected,
        method: this.get_selected_methode(),
        date: this.date,
        time_smallChart: this.$store.getters.chartSelector.time_smallChart,
      });
      return this.$store
        .dispatch("get_selected_data", this.$store.getters.chartSelector)
        .then(() => {
          try {

            //Processing the data returns from the server
            this.allChartData = getProcessedChartData(
              this.$store.getters.allChartData,
              this.x_axis_type,
              this.hasM1,
              this.timeSelected,
              this.date,
              this.$dayjs,
              this.seriesSelected == "energy_forecast" ||
              this.seriesSelected == "load_forecast"
            );
            // console.log("allchartData: ", this.allChartData);
            // this.overviewChartData(
            //   this.$store.getters.allChartData
            // );
            if (this.$store.getters.sn != -1) {
              this.data = this.allChartData[this.$store.getters.sn].data;
              // console.log("data_after: ", this.data);
              this.x_axis_type =
                this.allChartData[this.$store.getters.sn].x_axis_type;
            } else if ((this.seriesSelected != "m3" && this.seriesSelected != "m7") && (this.timeSelected == "day" || this.timeSelected == "quarter")) {
              const normalizedData = this.alignDataSets(this.$store.getters.allChartData);
              const newAllData = getProcessedChartData(
                normalizedData,
                this.x_axis_type,
                this.hasM1,
                this.timeSelected,
                this.date,
                this.$dayjs,
                this.seriesSelected == "energy_forecast" ||
                this.seriesSelected == "load_forecast"
              );
              this.data = this.allChartDataSummery(newAllData);
              // console.log("data_after: ", this.data);
              this.x_axis_type = this.data.x_axis_type;
              this.data = this.data.data;
            }
            else {
              // console.log("allChartData: ", this.allChartData);
              this.data = this.allChartDataSummery(this.allChartData);
              this.x_axis_type = this.data.x_axis_type;
              this.data = this.data.data;
            }

            //this.isLoading = false;
            this.showLoadingSpinner = false;
          } catch (error) {
            this.data = {};
          }
          this.$store.commit("setCurentChartData", this.data);
        })
        .catch((error) => {
          console.log("error getting chart's data", error);
        });
    },
    changeDate(step) {
      const currentDate = new Date(this.date);
      currentDate.setHours(0, 0, 0, 0);
      let newDate;
      if (this.timeSelected === 'day' || this.timeSelected === 'quarter') {
        newDate = addDays(currentDate, step);
      } else if (this.timeSelected === 'month') {
        newDate = addMonths(currentDate, step);
      } else if (this.timeSelected === 'year') {
        newDate = addYears(currentDate, step);
      } else if (this.timeSelected === 'interval') {
        newDate = addDays(currentDate, step);
      }
      this.date = newDate > this.today ? this.today : newDate;
      if (!(newDate >= this.today)) {
        this.drawChart();
      }
    },
    updateSeriesSelected(selected) {
      const hasM7 = selected.some(option => option.value === "m7");
      if (this.seriesSelected.includes("m7")) {
        this.tempSeriesSelected = selected.filter(option => option.value !== "m7");
        this.seriesSelected = this.tempSeriesSelected.map(option => option.value);
      }
      else if (hasM7 && selected.length > 1) {
        this.tempSeriesSelected = [{ value: "m7", text: i18n.t("bigchart.Energy") + i18n.t("bigchart.selectAlone") }];
        this.seriesSelected = ["m7"];
      } else {
        this.tempSeriesSelected = selected;
        this.seriesSelected = selected.map(option => option.value);
      }

      this.drawChart();
    },
    isOptionSelected(option) {
      return this.tempSeriesSelected.some(
        (selected) => selected.value === option.value
      );
    },
  },
  mounted() {
    this.tempSeriesSelected = this.seriesLabel.filter(option =>
      this.seriesSelected.includes(option.value)
    );

    if (this.timeSelected == "interval") {
      this.date = {
        start: this.$dayjs().subtract(1, "month").format("ddd MMM DD YYYY 00:mm:ss"),
        end: this.$dayjs().format("ddd MMM DD YYYY 00:mm:ss")
      };
    }
    EventBus.$on('calldrawChart', this.drawChart);
    this.canvasContext = this.$refs.chartWrapper.getContext("2d");
    this.drawChart();
    const thisInstance = this
    this.$root.$on('updatechart', function () {
      thisInstance.drawChart()
    })
    this.checkTimeAndDrawChart();
    this.intervalId = setInterval(this.checkTimeAndDrawChart, 60000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    // clearTimeout(this.loadingTimeout);
  },
  computed: {
    ...mapState(['darkMode']),
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    curentSn() {
      return this.$store.getters.sn;
    },

    //Changes
    // isLoading2() {
    //   return this.$store.getters.isLoading;
    // },
    timeSeries() {
      return getTimeSeries(this.seriesSelected);
    },
    series() {
      return getSeries(this.$store.getters.sn);
    },
    seriesLabel() {
      return getSeriesLabel(this.$store.getters.sn);
    },
    seriesLabel_short() {
      return getSeriesLabel_short(this.$store.getters.sn);
    },
    seriesUnit() {
      return getSeriesUnit();
    },
    lang() {
      return this.$i18n.locale;
    },
    isSmallScreen() {
      return window.matchMedia("(max-width: 768px)").matches;
    },
    isTouchScreen() {
      return window.matchMedia('(hover: none)').matches;
    },
    fixedDate() {
      if (this.timeSelected === 'interval' && !this.isSmallScreen) {
        return "DD.MM.YY";
      }
    },
    isAppleDevice() {
      return /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
    }
  },
};
</script>

<style scoped lang="scss">
.loading-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  /* Higher z-index to ensure it is above other content */
}

/*@media screen and (min-width: 320px) {

  .chart-wrapper {
    height: 350px;
  }

}
 @media screen and (min-width: 1024px) {

  .chart-wrapper {
    height: 350px;
  }

} 
@media screen and (min-width: 1430px) {

  .chart-wrapper {
    height: 440px;
  }

}
@media screen and (min-width: 2560px) {

  .chart-wrapper {
    height: 770px;
  }

}*/
.text-black {
  color: black !important;
}

.text-white {
  color: white !important;
}


.chart-arrow {
  color: #69758575;
  position: absolute;
  bottom: 0.0vh;
  transform: translateY(-50%);
  background-color: transparent !important;
  border: none;
  padding: 0px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  z-index: 5;
  border-radius: 50%;
}


.chart-arrow.left-arrow {
  left: 10%;
}

.chart-arrow.right-arrow {
  right: 10%;
}

.chart-arrow:active {
  color: #39567e;
  background-color: #39567e !important;
}

@media (hover: hover) and (pointer: fine) {
  .chart-arrow:hover {
    color: #39567e;
    transform: translateY(-70%) scale(1.5);
    background-color: transparent !important;
  }
}

.chart-wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  max-height: 100%;
}

#chart {
  flex: 1 1 auto;
  //max-height: 100% !important;
  min-height: auto !important;
  min-width: 100%;
}
</style>

<style lang="scss">
.multiselect {
  min-height: 38px;
}

.multiselect__content-wrapper {
  width: 100%;
  border-radius: 10px;
}

.multiselect__tags {
  min-height: 38px;
  border: 1px solid #cad1d7;
  background: none;
}

.multiselect--active .multiselect__tags {
  border-radius: 5px !important;
}

.multiselect__tags-wrap {
  display: inline;
  //display: flex;
}

.multiselect__tag {
  background: #42b883;
  margin-bottom: 0px;
}

.multiselect__placeholder {
  margin-bottom: 0px;
  padding-top: 0px;
}

.multiselect__option--selected {
  background: none;
  color: black;
}

.multiselect__option--selected::after {
  color: black;
}

.multiselect__option--highlight {
  background: #1967D2;
}

.multiselect__option--highlight::after {

  background: #1967D2;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #1967D2;
  color: #fff;
  display: flex;
}

.custom-dot {
  width: 16px;
  height: 16px;
  border: 2px solid #B0C5FB;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: absolute;
  right: 0;
}

.selected-dot {
  width: 8px;
  height: 8px;
  background-color: #B0C5FB;
  border-radius: 50%;
}

.custom-option {
  display: flex;
  align-items: center;
  padding: 5px;
}

.custom-selected {
  display: flex;
  align-items: center;
  padding: 3px;
}

.dark-mode {

  .multiselect__content-wrapper {
    background: #27293d;
    border-color: #454651;
    color: white;
    width: 100%;
  }

  .multiselect__content {
    color: white;
  }

  .multiselect__element {
    color: white;
  }

  .multiselect__option--selected {
    background: none;
    color: white;
  }

  .multiselect__option--selected::after {
    color: white;
  }

}


@media screen and (max-width: 1024px) {

  /*.chart-wrapper {
    height: 350px;
  }*/
  .multiselect__option--selected {
    background: none;
    color: white;
  }

  .multiselect__option {
    color: white;
    padding: 16px;
  }

  .multiselect__option--selected::after {
    color: white;
  }

  .multiselect__tags-wrap {
    display: flow-root;
  }

  .multiselect__content-wrapper {
    position: fixed;
    top: 34%;
    left: 3%;
    width: 94% !important;
    height: fit-content;
    z-index: 1050;
    overflow-y: auto;
    padding: 16px;
    box-shadow: none;
    border: 1px solid #454651 !important;
    border-radius: 20px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.0);
    background: #222631;
    max-height: 100% !important;
  }

  .custom-dot {
    width: 20px;
    height: 20px;
    //border: 2px solid #C6CAD5;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    position: absolute;
    right: 0;
  }

  .selected-dot {
    width: 10px;
    height: 10px;
    background-color: #B0C5FB;
    border-radius: 50%;
  }

  .custom-option {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  .custom-selected {
    display: flex;
    align-items: center;
    padding: 3px;
  }

}
</style>
