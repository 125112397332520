<template>
    <div>
        <VueDatePicker v-model="tempChartDate" color="#285891" fullscreen-mobile :format="formattedDate"
            @onChange="applyFinalDate" :allow-overflow="false" :locale="{ lang: lang }" :type="datePickerType"
            :max-date="today" :range="timeSelected === 'interval'" :style="dynamicStyle" />
    </div>
</template>

<script>
export default {
    props: {
        initialDate: {
            type: [String, Object],
            required: true,
        },
        timeSelected: {
            type: String,
            required: true,
            default: 'week',
        },
        lang: {
            type: String,
            default: 'de',
        },
    },
    data() {
        return {
            today: new Date(),
            tempChartDate: this.initialDate || this.defaultDate(),
        };
    },
    computed: {
        datePickerType() {
            return this.timeSelected === "week"
                ? "date"
                : this.timeSelected === "month"
                    ? "date"
                    : this.timeSelected === "year"
                        ? "year"
                        : this.timeSelected === "interval"
                            ? "date"
                            : this.timeSelected;
        },
        formattedDate() {
            let tem = '';
            let dateRegex_week = /^\d{2}\.\d{2}\.\d{4}$/;
            let date_week = /^\d{4}\-\d{2}\-\d{2}$/;
            let dateRegex_month = /^\d{2}\.\d{4}$/;
            let date_month = /^\d{4}\-\d{2}$/;
            let dateRegex_year = /^\d{4}$/;
            if (this.timeSelected === 'week') {
                if (dateRegex_week.test(this.tempChartDate)) {
                    tem = this.tempChartDate;
                } else if (dateRegex_month.test(this.tempChartDate) || date_month.test(this.tempChartDate) || date_week.test(this.tempChartDate)) {
                    tem = this.$dayjs(this.tempChartDate).format('DD.MM.YYYY');
                    // return tem;
                }
                else {
                    tem = this.$dayjs(this.tempChartDate).format('DD.MM.YYYY');
                    // return tem;
                }
            } else if (this.timeSelected === 'month') {
                if (this.tempChartDate.includes('.')) {
                    const [day, month, year] = this.tempChartDate.split('.');
                    // return `${month}.${year}`
                    tem = `${month}.${year}`;
                } else if (this.tempChartDate.includes('-')) {
                    const [year, month, day] = this.tempChartDate.split('-');
                    // return `${month}.${year}`
                    tem = `${month}.${year}`;
                }
                else {
                    tem = this.$dayjs(this.tempChartDate).format('MM.YYYY');
                }
            } else if (this.timeSelected === 'year') {
                tem = this.$dayjs(this.tempChartDate).format('YYYY');
            } else if (this.timeSelected === "interval") {
                if (typeof this.tempChartDate === "object" && this.tempChartDate.start && this.tempChartDate.end) {
                    tem = "DD.MM.YY";
                }
            }

            this.applyFinalDate();
            return tem;
        },
        dynamicStyle() {
            let withd = "7rem";
            if (this.timeSelected === "interval") { withd = "10.6rem"; }
            else if (this.timeSelected === "month") { withd = "6rem"; }
            else if (this.timeSelected === "year") { withd = "5rem"; }
            else if (this.timeSelected === "week") { withd = "7rem"; }
            return {
                maxWidth: withd,
                right: "0",
                top: ".1rem",
            };
        },
    },
    methods: {
        applyFinalDate() {
            if (this.timeSelected === "interval") {
                const range = {
                    start: this.tempChartDate.start || this.$dayjs().subtract(1, "week").format("DD.MM.YYYY"),
                    end: this.tempChartDate.end || this.$dayjs(this.today).format("DD.MM.YYYY"),
                };
                this.$emit("date-selected", range);
            } else {
                this.$emit("date-selected", this.tempChartDate);
            }
        },
        defaultDate() {
            if (this.timeSelected === "interval") {
                return { start: this.$dayjs().subtract(1, "week").format("DD.MM.YYYY"), end: this.today };
            }
            return this.$dayjs(this.today).format("DD.MM.YYYY");
        },
    },
    watch: {
        tempChartDate(newValue) {
            console.log('WATCH tempChartDate updated:', newValue);
        }
    }
};
</script>
<style scoped>
.vd-wrapper {
    right: 0 !important;
    top: .1rem !important;
}
</style>
<style>
.vd-picker__input-icon {
    margin-top: 2.8px !important;
}
</style>