<template>
  <div :key="refresh">
    <!-- <h5 class="info-text">{{$t('update.step2header')}}</h5> -->
    <div class="row justify-content-center" :key="refresh">
      <div v-if="!started && !waiting" class="col-lg-10 text-center" :key="refresh">
        {{ $t("update.step2description") }}
      </div>
      <div v-if="!started && waiting" class="col-lg-10 text-center" :key="refresh">
        {{ $t("update.waiting2min") }}
      </div>

      <div v-if="started" class="col-lg-10 text-center" :key="refresh">
        <h3 style="margin: 0" :key="refresh"><span v-if="updateType">{{ updateType }} </span>Update
          <span :class="{ 'text-success': one_is_done }">
            {{ completedUpdates }}
          </span>
          / {{ totalUpdates }}
          <h5 v-if="one_is_done && completedUpdates == totalUpdates" class="text-success" style="margin: 0">
            {{ $t("update.finish") }}
          </h5>
          <h5 v-else-if="one_is_done" class="text-success" style="margin: 0" v-html="$t('update.oneOfMultiple')"></h5>
        </h3>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      initialAvailableUpdates: null,
      completedUpdates: 1,
      total: 0,
      one_is_done: false,
    };
  },
  computed: {
    sn() {
      // console.log("getSnUpdate", this.$store.getters.getSnUpdate);
      return this.$store.getters.getSnUpdate;
    },

    started() {
      // console.log("updateStarted", this.$store.getters.updateStarted);
      const updateStarted = this.$store.getters.updateStarted;
      return updateStarted[this.sn]?.updateStarted === "started";
    },
    waiting() {
      const updateStarted = this.$store.getters.updateStarted;
      return updateStarted[this.sn]?.updateStarted === "waiting";
    },

    totalUpdates() {
      // console.log("totalUpdates", this.$store.getters.totalUpdates[this.sn].totalUpdates);
      let totalUpdates_DB = this.$store.getters.totalUpdates[this.sn].totalUpdates;
      const updateProgress = this.$store.getters.updateProgress;

      if (updateProgress[this.sn].updateProgress > 99) {
        this.one_is_done = true;
      }

      if (totalUpdates_DB < 10) {
        this.total = totalUpdates_DB;
      } else if (this.one_is_done && updateProgress[this.sn].updateProgress == 100 && this.total !== this.completedUpdates) {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10);
        this.total = totalUpdates_DB % 10;
      } else if (this.one_is_done && updateProgress[this.sn].updateProgress <= 2) {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10) + 1;
        this.total = totalUpdates_DB % 10;
        this.one_is_done = false;
      } else {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10) + 1;
        this.total = totalUpdates_DB % 10;
      }
      if (this.total < this.completedUpdates) {
        this.completedUpdates = this.total;
      }
      // console.log("this.is_one_done", this.one_is_done, 'this.completedUpdates', this.completedUpdates, 'this.total', this.total);
      return this.total;
    },
    updateType() {
      if (this.$store.getters.updateType[this.sn]) {
        console.log("updateType", this.$store.getters.updateType[this.sn].updateType);
        if (this.$store.getters.updateType[this.sn].updateType === 1) {
          return "Gateway";
        } else {
          return "Master";
        }
      } else {
        return false;
      }
    },
    refresh() {
      console.log("Progress", this.$store.getters.updateProgress?.[this.sn]?.updateProgress);
      return this.$store.getters.updateProgress?.[this.sn]?.updateProgress;
    },
  },
  methods: {
    validate() {
      this.$emit("on-validated", true, this.model);
      return Promise.resolve(true);
    },
  },
};
</script>
<style></style>
