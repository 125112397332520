
import i18n from "@/i18n";

export const createDataSet = (

  _label,

  _data,
  color

) => {
  return {
    label: _label,

    data: _data,

    fill: false,
    borderColor: color,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: color,
    pointBorderColor: "rgba(255,255,255,0)",
    pointHoverBackgroundColor: color,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 1,

  };
};
export const createbarDataSet = (

  _label,
  _backgroundColor,
  _borderColor,
  _data,
  _radius,

  _fill = false,
  _steppedLine = false,
  _intersect = false

) => {
  return {
    label: _label,
    //type: this.getChartType(),
    backgroundColor: _backgroundColor,
    borderColor: _borderColor,
    fill: true,
    data: _data,
    steppedLine: _steppedLine,
    radius: 1,
    tension: 0.5,

    // borderWidth: 0,
    interaction: {
      intersect: _intersect,
    },
  };
};
const dataSorting = (refactredData) => {
  return refactredData.sort((a, b) => {
    if (a.time < b.time) {
      return -1;
    }
    if (a.time > b.time) {
      return 1;
    }
    return 0;
  });
};

const dataRefactoring = (data) => {
  let n_name = "";
  // console.log("data::", data);
  try {
    let prefex = Object.keys(data[0])[0].split("_")[0];
    n_name = prefex + "_n";
    // console.log("nnnn: " + n_name);
  } catch (error) {
    if (data.length > 0) {
      console.warn(error);
    }
  }

  return data.map((el) => {
    let n = 1;
    try {
      n = el[n_name];
    } catch (error) {
      console.warn(error);
    }
    return Object.fromEntries(
      Object.entries(el).map(([key, value]) =>
      // Modify key here
      {
        if (n === undefined) n = 1;

        key = key.replace(/([a-z]*[msf](?=_)_)/g, "");
        if (!["time", "m3", "m7"].includes(key)) value = (value / n).toFixed(0);
        else if (["m3", "m7"].includes(key)) value = (value).toFixed(0);
        // value = (value / n).toFixed(0);

        return [key, value];
      }
      )
    );
  });
};

const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
const timeReformating = (
  data,
  x_axis_type,
  hasM1,
  timeSelected,
  date,
  dayjs,
  ef_data = false
) => {
  x_axis_type = "category";

  let start = null;
  let end = null;
  if (timeSelected === "interval") {
    if (data.length > 0) {
      start = new Date(dayjs(date.start).format("ddd MMM DD YYYY HH:mm:ss"));
      end = new Date(dayjs(date.end).format("ddd MMM DD YYYY HH:mm:ss"));
      // console.log("start", start, "end", end);
    }
  }
  data.map((d) => {
    // console.log("d.time", d.time);
    const time = new Date(
      dayjs.utc(d.time).format("ddd MMM DD YYYY HH:mm:ss")
    );
    // console.log("time", time);
    if (timeSelected === "quarter") {

      d.time = time;

    }
    if (timeSelected === "day") {
      if (i18n.d(time, "day", "en") === "24") {
        d.time = "00";
      }
      else {
        d.time = i18n.d(time, "day", "en");
        // console.log("day", d.time);


      }

    }
    if (timeSelected === "month") {
      d.time = i18n.d(time, "month");
      // console.log("month", d.time);
    }
    if (timeSelected === "year") {
      d.time = i18n.d(time, "year", "en");
      // console.log("year", d.time);
    }
    if (timeSelected === "interval") {
      // console.log("start", start, "end", end);
      if (start.getFullYear() === end.getFullYear()) {
        // console.log("start.getMonth()", start.getMonth() + 1, "end.getMonth()", end.getMonth() + 1);
        if (start.getMonth() + 1 === end.getMonth() + 1) {
          d.time = i18n.d(time, "month");
          // console.log("d.time same year & month", d.time);
        } else {
          // Different month but same year
          d.time = dayjs(time).format("DD.MM");
          // console.log("d.time same year", d.time);
        }
      } else {
        // Different year
        d.time = dayjs(time).format("DD.MM.YYYY");
        // console.log("d.time Different year", d.time);
      }
    }

  });

  let entry = null;
  if (hasM1)
    entry = {
      m1: null,
      m2: null,
      m4: null,
      m5: null,
      m6: null,
      time: null,
    };
  else
    entry = {
      m3: null,
      m7: null,
      time: null,
    };
  if (ef_data) {
    entry = {
      data: null,
      time: null,
      sn_id: null,
      id: null,
    };
  }
  let formatedData = [];

  if (timeSelected === "year") {
    let monthes = [
      { time: "Jan" },
      { time: "Feb" },
      { time: "Mar" },
      { time: "Apr" },
      { time: "May" },
      { time: "Jun" },
      { time: "Jul" },
      { time: "Aug" },
      { time: "Sep" },
      { time: "Oct" },
      { time: "Nov" },
      { time: "Dec" },
    ];
    for (let index = 0; index < monthes.length; index++) {
      let cc = { ...entry };
      let time = monthes[index].time;
      let exsist = false;
      data.map((d) => {
        if (time == d.time) {
          cc = d;
          formatedData.push(cc);
          exsist = true;
        }
      });

      if (!exsist) {
        cc = { ...entry };
        cc.time = time;
        // console.log(time);
        formatedData.push(cc);
      }
    }
    //entry.time = "sadas";
    //console.log("dataFormated", formatedData);
    return { data: formatedData, x_axis_type: x_axis_type };
  } else if (timeSelected === "month") {
    let selectedDate = new Date(date);

    //  let days = [];

    for (
      let index = 1;
      index <=
      daysInMonth(selectedDate.getMonth() + 1, selectedDate.getFullYear());
      index++
    ) {
      // days.push({ time: index });

      let cc = { ...entry };
      const time = index;
      let exsist = false;
      data.map((d) => {
        if (parseInt(time) == parseInt(d.time)) {
          cc = d;

          formatedData.push(cc);
          cc.time = parseInt(cc.time);

          exsist = true;
        }
      });

      if (!exsist) {
        cc = { ...entry };
        cc.time = time;
        formatedData.push(cc);
      }
      exsist = false;
    }

    return { data: formatedData, x_axis_type: x_axis_type };
    //console.log("days", days);
  } else if (timeSelected === "day") {
    for (let index = 0; index < 24; index++) {
      // days.push({ time: index });
      let time = "";
      let cc = { ...entry };
      if (index < 10) time = "0" + index;
      else time = index + "";

      let exsist = false;
      data.map((d) => {
        if (time == d.time) {
          cc = d;
          formatedData.push(cc);
          exsist = true;
        }
      });

      if (!exsist) {
        cc = { ...entry };
        cc.time = time;
        formatedData.push(cc);
      }
      exsist = false;
    }
  } else if (timeSelected === "interval") {

    let start = new Date(date.start);
    let end = new Date(date.end);

    let currentDate = new Date(start);
    while (currentDate <= end) {
      let time = currentDate;

      if (start.getFullYear() === end.getFullYear()) {
        if (start.getMonth() + 1 === end.getMonth() + 1) {
          time = i18n.d(time, "month");
        } else {
          time = dayjs(time).format("DD.MM");
        }
      } else {
        time = dayjs(time).format("DD.MM.YYYY");
      }

      let cc = { ...entry };
      let exsist = false;
      data.map((d) => {
        // console.log(time, d.time);
        if (time == d.time) {
          cc = d;
          // console.log("exsist", time, d.time);
          formatedData.push(cc);
          cc.time = time;

          exsist = true;
        }
      });

      if (!exsist) {
        cc = { ...entry };
        cc.time = time;
        formatedData.push(cc);
      }
      exsist = false;
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return { data: formatedData, x_axis_type: x_axis_type };
    //console.log("days", days);
  }
  else {
    for (let time = 0; time < 24; time++) {
      let cc = { ...entry };

      let exsist = false;
      data.map((d) => {
        if (time == d.time.getHours()) {
          cc = d;
          formatedData.push(cc);
          exsist = true;
        }
      });

      if (!exsist) {
        cc = { ...entry };
        let day = new Date(date);

        cc.time = new Date(
          day.getFullYear(),
          day.getMonth(),
          day.getDate(),
          time,
          day.getMinutes(),
          day.getSeconds()
        );
        formatedData.push(cc);
      }
      exsist = false;
    }

    x_axis_type = "time";
  }
  //console.log("dataFormated", formatedData);
  return { data: formatedData, x_axis_type: x_axis_type };
};
export const getProcessedChartData = (
  data,
  x_axis_type,
  hasM1,
  timeSelected,
  date,
  dayjs,
  ef_data = false
) => {
  try {
    let finishedData = {};

    Object.keys(data).map((sn) => {
      let refactredData = dataRefactoring(data[sn]);
      // console.log("refactredData", refactredData);
      let sortedData = dataSorting(refactredData);
      // console.log("sortedData[sn]", sortedData);
      finishedData[sn] = timeReformating(
        sortedData,
        x_axis_type,
        hasM1,
        timeSelected,
        date,
        dayjs,
        ef_data
      );
    });

    return finishedData;
  } catch (error) {
    console.log(`error: ${error}`);
  }
};
